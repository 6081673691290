<template>
  <div>
    <slot name="title">
      <div class="text-caption mb-2">
        {{ $t('Contribution weight mode') }}
      </div>
    </slot>
    <v-radio-group
      :mandatory="true"
      :value="value"
      row
      @change="$emit('change', $event)"
    >
      <v-radio :label="$t('Percent')" :ripple="false" value="percent" />
      <v-radio :label="$t('Fraction')" :ripple="false" value="fraction" />
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: 'FormContributionWeightMode',
  props: {
    value: {
      type: String,
      default: '',
      validator (value) {
        return ['', 'percent', 'fraction'].includes(value)
      }
    }
  }
}
</script>
