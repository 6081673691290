<template>
  <router-link :to="to" class="grey--text text-caption text-decoration-none">
    <v-icon small v-text="icons.back" />
    {{ text }}
  </router-link>
</template>

<script>
import {mdiChevronLeft} from '@mdi/js'

export default {
  name: 'AppLinkBackToDeposits',
  props: {
    to: {type: [Object, String], required: true},
    text: {type: String, required: true},
  },
  data () {
    return {
      icons: {
        back: mdiChevronLeft
      }
    }
  }
}
</script>
