<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="500px"
  >
    <template v-if="project.id" v-slot:activator="{on}">
      <v-btn
        :ripple="false"
        color="transparent"
        elevation="0"
        fab
        x-small
        v-on="on"
      >
        <v-icon color="grey">
          {{ icons.edit }}
        </v-icon>
      </v-btn>
    </template>

    <template v-else v-slot:activator="{on}">
      <v-btn
        :ripple="false"
        class="mt-1"
        color="primary"
        outlined
        rounded
        v-on="on"
      >
        {{ $t('Create project') }}
      </v-btn>
    </template>

    <v-card class="pa-6">
      <v-form :key="resetKey" @submit.prevent="save">
        <v-card-title>
          <h3 class="mx-auto mb-6">
            {{ project.id ? $t('Edit project') : $t('Create project') }}
          </h3>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-text-field
            ref="projectName"
            v-model="localProject.name"
            :error-messages="serverFeedback"
            :label="$t('Project title')"
            :loading="saving"
            :rules="rules.name"
            clearable
            outlined
          />
        </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <v-row align="center">
            <v-col v-if="isDeleteAllowed" class="py-0" cols="auto">
              <v-btn
                :loading="removing"
                :ripple="false"
                color="error"
                outlined
                rounded
                small
                @click="remove"
              >
                {{ $t('Delete') }}
              </v-btn>
            </v-col>
            <v-col class="py-0">
              <v-btn
                :ripple="false"
                block
                outlined
                rounded
                @click="dialog = false"
              >
                {{ $t('Cancel') }}
              </v-btn>
            </v-col>
            <v-col class="py-0">
              <v-btn
                :loading="saving"
                :ripple="false"
                block
                color="primary"
                elevation="0"
                rounded
                type="submit"
              >
                {{ project.id ? $t('Save') : $t('Create') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions} from 'vuex'
import {mdiPencilOutline, mdiPlus} from '@mdi/js'
import error401handler from '@/mixins/error-401-handler'

export default {
  name: 'ProjectDialogAddEdit',
  mixins: [error401handler],
  props: {
    project: {type: Object, default: () => ({id: null, name: ''})}
  },
  data () {
    return {
      resetKey: Date.now(),
      localProject: {id: null, name: ''},
      dialog: false,
      saving: false,
      removing: false,
      serverFeedback: null,
      rules: {
        name: [
          v => !!v || this.$t('Project name is required')
        ]
      },
      icons: {
        edit: mdiPencilOutline,
        plus: mdiPlus
      }
    }
  },
  computed: {
    isDeleteAllowed () {
      let hasDeposits = this.project.depositsCount?.registered > 0 ||
        this.project.depositsCount?.inProgress > 0 ||
        this.project.depositsCount?.draft > 0
      return this.project.id && !hasDeposits
    }
  },
  watch: {
    dialog (newVal) {
      if (newVal) {
        if (!this.localProject.name) {
          this.localProject = {...this.project}
        }
        setTimeout(() => {
          this.$refs.projectName.$refs.input.focus()
        }, 100)
      } else {
        if (!this.localProject.name) {
          this.resetForm()
        }
      }
    }
  },
  methods: {
    ...mapActions({
      createProject: 'projects/prCreate',
      updateProject: 'projects/prUpdate',
      removeProject: 'projects/prRemove'
    }),
    async save () {
      this.saving = true
      try {
        if (this.project.id) {
          await this.updateProject(this.localProject)
        } else {
          let projectId = await this.createProject(this.localProject.name)
          this.$emit('create', projectId)
        }
        this.saving = false
        this.dialog = false

        this.resetForm()
      } catch (e) {
        this.saving = false
        if (!this.handleError(e) && e.response?.status === 422) {
          this.serverFeedback = e.response.data.message.name
        }
      }
    },
    async remove () {
      this.removing = true
      try {
        await this.removeProject(this.project.id)
        this.removing = false
        this.dialog = false
      } catch (e) {
        this.removing = false
        if (!this.handleError(e) && e.response?.status === 422) {
          this.serverFeedback = e.response.data.message.name
        }
      }
    },
    resetForm () {
      this.localProject = {id: null, name: ''}
      // Must change form's key to clear error messages
      this.resetKey = Date.now()
    }
  }
}
</script>
