<template>
  <div>
    <v-text-field
      v-if="mode === 'percent'"
      :append-icon="icons.percent"
      :disabled="disabled"
      :error-messages="feedback"
      :label="$t('Percent')"
      :rules="rules.percent"
      :value="percent(value)"
      autocomplete="off"
      max="100"
      min="1"
      type="number"
      outlined
      @change="$emit('change', $event)"
      @keydown="keydownPercent"
    />
    <v-text-field
      v-if="mode === 'fraction'"
      :disabled="disabled"
      :error-messages="feedback"
      :label="$t('Fraction')"
      :rules="rules.fraction"
      :value="value"
      autocomplete="off"
      placeholder="_/_"
      outlined
      validate-on-blur
      @change="$emit('change', $event)"
      @keydown="keydownFraction"
    />
  </div>
</template>

<script>
import {mdiPercentOutline} from '@mdi/js'

const controlKeys = [
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Tab',
  'Enter',
  'Escape',
  'Backspace',
  'Delete',
  'Home',
  'End'
]
const digitsKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

export default {
  name: 'FormContributionWeight',
  props: {
    disabled: {type: Boolean, default: false},
    feedback: {type: String, default: ''},
    mode: {
      type: String,
      default: '',
      validator (value) {
        return ['', 'percent', 'fraction'].includes(value)
      }
    },
    value: {type: String, required: true}
  },
  data () {
    return {
      rules: {
        percent: [
          v => {
            return v * 1 <= 100 || this.$t('Percent cannot be more than 100')
          }
        ],
        fraction: [
          v => {
            return /^\s*\d+\s*\/\s*\d+\s*$/.test(v) ||
              this.$t('Use value like 1/3')
          }
        ]
      },
      icons: {
        percent: mdiPercentOutline,
      }
    }
  },
  methods: {
    percent (cw) {
      return Math.trunc(parseFloat(cw) * 100)
    },
    keydownPercent (e) {
      if (![...digitsKeys, ...controlKeys].includes(e.key)) {
        e.preventDefault()
      }
    },
    keydownFraction (e) {
      if (!['/', ...digitsKeys, ...controlKeys].includes(e.key)) {
        e.preventDefault()
      }
    },
  }
}
</script>
